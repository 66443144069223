
import CloudFun, { Condition, defineComponent, Operator, ref, reactive, Sorting, SortOrder } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";
import RecordTable from "@/components/document/RecordTable.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    RecordTable
  },
  setup() {
    const model = CloudFun.current?.model
    const user = CloudFun.current?.user;
    const enums = CloudFun.current?.enums as any;
    const grid = ref<any>({});
    const isDisabled = ref(false);

    const gridOptions: GridOptions = {
      stripe: false,
      title: '審稿管理',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      multiselect: false,
      columns: [{
        field: 'IndustryCategoryName',
        title: '產業類別',
        width: '10%',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: false,
      },
      {
        field: 'Title',
        title: '標題',
        width: '30%',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        treeNode: false,
        editRender: { name: '$input', immediate: true, attrs: { type: 'text' } },
        filters: [{ checked: false, label: '包含' }],
        slots: { filter: 'filter-name' }
      },
      {
        field: 'ReportUser.Name',
        title: '撰寫人',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
      },
      {
        field: 'ProofUser.Name',
        title: '校稿人',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
      },
      {
        field: 'ReviewUser.Name',
        title: '審稿人',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
      },
      {
        field: 'ReviewDate',
        title: '審稿截止日期',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        formatter: ({ cellValue }) => formatDate(new Date(cellValue), 'yyyy/MM/dd')
      },
      {
        field: 'Status',
        title: '狀態',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        formatter: ({ cellValue }) => {
          const item = model?.enums && Object.values(model?.enums.DocumentStatus).find((e) => e.Value === cellValue);
          return item ? item.Name : "";
        }
      },
      // {
      //   field: 'TopicType',
      //   title: '主題',
      //   showHeaderOverflow: true,
      //   showOverflow: true,
      //   sortable: true,
      //   formatter: ({ cellValue }) => {
      //     const item = model?.enums && Object.values(model?.enums.TopicType).find((e) => e.Value === cellValue);
      //     return item ? item.Name : "";
      //   }
      // },
      ],
      promises: {
        query: model ? (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          params.condition = new Condition("ReviewUserId", Operator.Equal, user?.Id)
            .and(
              new Condition("Status", Operator.Equal, enums.DocumentStatus.Review.Value)
            ).and(params.condition!);
          return model.dispatch('document/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('document/query') : undefined,
        save: model ? (params) => model.dispatch('document/save', params) : undefined
      },
      modalConfig: { width: "70%", height: "80%", showFooter: true }
    }

    const reviewForm = ref<any>();
    const reviewFormReportFileGrid = ref<any>()
    const reviewFormFileGrid = ref<any>();
    const reviewFormOptions = {
      options: {
        titleWidth: 80,
        titleAlign: 'right',
        items: [
          {
            field: 'Title',
            title: '標題',
            span: 12,
            itemRender: { name: '$input', props: { placeholder: '請輸入標題', readonly: true } }
          },
          {
            field: 'PublishedDate',
            title: '出版日期',
            span: 12,
            titleWidth: 80,
            itemRender: {
              name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: true }
            }
          },
          {
            field: 'TopicTypeName',
            title: '主題',
            span: 12,
            itemRender: { name: '$input', props: { readonly: true } }
            // itemRender: { name: "$select", for, options: model ? Object.values(model.enums.TopicType).map(e => { return { label: e.Name, value: e.Value } }) : [], attrs: { readonly: true } }
          },
          {
            field: 'ReportUserId',
            title: '負責人',
            span: 12,
            slots: { default: "column-report-user-id" }
          },
          // {
          //   title: '產業類別',
          //   span: 24,
          //   slots: { default: "column-programs" }
          // },
          {
            span: 24,
            slots: { default: "column-report-files" }
          },
          {
            title: "檢核項目",
            span: 24,
          },
          {
            span: 24,
            slots: { default: "column-check-list" }
          },
          {
            field: 'IgnoreReproof',
            title: '重新送審不需校稿',
            titleWidth: '150',
            span: 12,
            itemRender: { name: '$checkbox' }
          },
          // {
          //   field: 'Comment',
          //   title: '意見',
          //   span: 24,
          //   itemRender: { name: '$textarea', props: { placeholder: '請輸入意見' } }
          // },
          {
            span: 24,
            slots: { default: "column-comment-files" }
          },
          {
            span: 24,
            slots: { default: "column-document-records" }
          },
        ]
      } as VxeFormProps,
      uploadOptions: {
        title: "上傳審稿附件",
        promises: {
          query() {
            return model?.dispatch('files/query', {
              condition: new Condition("DocumentRecordId", Operator.IsNull)
                .and("Type", Operator.Equal, 3).and("DocumentId", Operator.Equal, documentId.value)
            });
          },
          insert(formData: FormData) {
            return model?.dispatch('files/insert', { formData, mode: 'other', fileType: 3, entityId: 'Document_' + documentId.value });
          },
          delete(id: number) {
            return model?.dispatch('files/delete', id);
          }
        }
      },
      reportFileUploadOptions: {
        title: "上傳研究報告",
        canDelete: false,
        canUpload: false,
        promises: {
          query() {
            return grid.value.editingRow
              ? new Promise<any>(resolve => {
                // 最後一筆record的檔案
                const availableStatuses = [enums.DocumentStatus.Writing.Value, enums.DocumentStatus.ChangedReturn.Value, enums.DocumentStatus.Changed.Value, enums.DocumentStatus.ReviewedReturn.Value, enums.DocumentStatus.ProofReadReturn.Value];
                model!.dispatch("documentRecord/query", {
                  condition: new Condition("DocumentId", Operator.Equal, grid.value.editingRow.Id)
                    .and("BeforeStatus", Operator.In, availableStatuses),
                  sortings: new Sorting('CreatedTime', SortOrder.Descending),
                  page: 1,
                  pageSize: 1
                }).then(async resp => {
                  const recordId = resp.data[0].Id
                  const files = await model?.dispatch('files/query', {
                    condition: new Condition("DocumentRecordId", Operator.Equal, recordId)
                      .and(
                        new Condition("Type", Operator.Equal, 3)
                          .or("Type", Operator.Equal, 4)
                      )
                  });
                  resolve(files);
                });
              })
              : undefined;
            // return grid.value.editingRow
            //   ? model?.dispatch('files/query', {
            //     condition: new Condition("DocumentId", Operator.Equal, grid.value.editingRow.Id)
            //       .and("Type", Operator.Equal, 5)
            //   })
            //   : undefined;
          }
        },
      },
      approve() {
        grid.value.editingRow.FileIds = reviewFormFileGrid.value.getData().map((e: any) => e.Id);
        model!.dispatch("document/approve", grid.value.editingRow).then(() => {
          grid.value.refresh();
          grid.value.isModalPopup = false;
        }, failure => CloudFun.send('error', { subject: '操作失敗！', content: failure }));
      },
      reject() {
        grid.value.editingRow.FileIds = reviewFormFileGrid.value.getData().map((e: any) => e.Id);
        model!.dispatch("document/rejectReview", grid.value.editingRow).then(() => {
          grid.value.refresh();
          grid.value.isModalPopup = false;
        }, failure => CloudFun.send('error', { subject: '操作失敗！', content: failure }))
      }
    };

    const programSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇類別",
      textField: "Name",
      valueField: "Id",
      readonly: true,
      multiselect: true,
      columns: [
        {
          field: "Name",
          title: "名稱",
          width: "40%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        }, {
          field: "FullName",
          title: "全名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("program/find", value), // eslint-disable-line
        query: params => {
          params.condition = new Condition("Published", Operator.Equal, true)
            .and("ParentId", Operator.IsNotNull)
            .and(params.condition!);
          return model!.dispatch("program/query", params) // eslint-disable-line
        }
      }
    };

    const userSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      readonly: true,
      columns: [
        {
          field: "Account",
          title: "帳號",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "姓名",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("user/find", value), // eslint-disable-line
        query: params => model!.dispatch("user/query", params) // eslint-disable-line
      }
    };

    const fileGrid = ref<any>()
    const fileModal = reactive({
      visible: false,
      selectedRow: {} as any,
      uploadOptions: {
        canDelete: false,
        canUpload: false,
        promises: {
          query() {
            return fileModal.selectedRow
              ? model?.dispatch('files/query', {
                condition: new Condition("DocumentId", Operator.Equal, fileModal.selectedRow.Id)
                  .and("Type", Operator.Equal, 5)
              })
              : undefined;
          }
        }
      },
      show(row: any) {
        fileModal.visible = true;
        fileModal.selectedRow = row;
        if (fileGrid.value) fileGrid.value.refresh();
      }
    })

    const documentId = ref<any>();

    return {
      grid,
      gridOptions,
      reviewForm,
      reviewFormReportFileGrid,
      reviewFormFileGrid,
      reviewFormOptions,
      model,
      programSelectorOptions,
      userSelectorOptions,
      fileGrid,
      fileModal,
      documentId,
      isDisabled,
    }
  },
  methods: {
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id ? await this.$model.dispatch('document/find', row.Id) : undefined
      Object.assign(row, entity);
      row.TopicTypeName = (Object.values(this.$model.enums.TopicType) as any[]).find(e => e.Value === row.TopicType).Name;
      row.ReviewChecklist = {
        IsAbstract: false,
        IsContent: false,
        IsArticle: false,
        IsChart: false,
      };
      if (row.Id) this.documentId = row.Id
      if (this.reviewFormReportFileGrid) this.reviewFormReportFileGrid.refresh();

      callback()
    }
  }
})
